import { useState } from "react";
import { Outlet, Link } from "react-router-dom";

export default function Home ( props ) {

    const { currentLesson, setCurrentLesson } = props;



    return (
        <>

            <h1 className="text-center fs-5 mt-5">Вивчаємо Англійську мову</h1>

            <div className="container">
                <div className="row justify-content-center">
                <div className="col-md-10 col-lg-7 col-xl-6 col-xxl-5  mt-5">

                    <div className="box-with-cover-wrapper">
                        <Link to="/lessons" className="box-with-cover box-with-cover_type-1">
                            <h6 className="box-with-cover__title">Вивчати слова</h6>
                        </Link>
                    </div>

                </div>
                </div>
            </div>

        </>
    )
    
}
  