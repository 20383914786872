
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import Word from './Word'

export default function Lesson( { currentLesson } ) {

  const navigate = useNavigate();

  useEffect(() => {
    // check if currentLesson has any data (user might reload /lesson page)
    if (Object.keys(currentLesson).length == 0) {
      navigate('/Lessons')
    }
  }, [currentLesson]);

  return (

    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-7 col-xl-6 col-xxl-5  my-5">
          <h4 className="mb-4">{currentLesson.name}</h4>
          <div className="words-items">

            { Object.keys(currentLesson).length > 0 ?
            
              currentLesson.words.map(item =>

              <Word
                key = {item.word} 
                item = {item}
              ></Word>

            ) : 
            <div>No Data</div>
          }
         
          </div>
        </div>
      </div>
    </div>

  )

}