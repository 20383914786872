import { useState } from "react";


export default function Word ( {item} ) {

    let [speaking, setSpeaking] = useState(false);

    function speak(text) {

        setSpeaking(true);

        // Create a SpeechSynthesisUtterance
        // const utterance = new SpeechSynthesisUtterance("Welcome to this tutorial!");
        
        const utterance = new SpeechSynthesisUtterance(text);
      
        // Select a voice
        const voices = speechSynthesis.getVoices();
        // utterance.voice = voices[1]; // Choose a specific voice
        // utterance.lang = "en-US";
        // utterance.lang = "uk-UA";

        utterance.lang = "en-GB";
      
        // Speak the text
        speechSynthesis.speak(utterance);


        // if speak is ended
        utterance.onend = (event) => {
            setSpeaking(false)
          };

      }

    return (
        <div className={ speaking ? "word-item active" : "word-item" } onClick={ () => ( speak(item.word) ) }>
            <div className="word-item__word">{item.word}</div>
            <div className="word-item__translate">{item.translate}</div>
        </div>
    )
}