import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import logo from './logo.svg';
import './App.css';

import jsondata from './jsondata.json'

import Home from './pages/Home'
import Lessons from './components/Lessons'
import Lesson from './components/Lesson'


function App() {

  const [appData, setAppData] = useState (jsondata);
  const [currentLesson, setCurrentLesson] = useState ([]);

  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" index element={
          <Home 
            { ... { currentLesson, setCurrentLesson } }
          />}
        />
        <Route path="lessons" element={
          <Lessons
            lessons = { appData.lessons }
            setCurrentLesson = { setCurrentLesson }/>
          }
        />
        <Route path="lesson" element={
          <Lesson 
            currentLesson = { currentLesson }
          />} />

        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>

  );


}

export default App;
