import { Link } from "react-router-dom";

export default function Lessons( { lessons, setCurrentLesson } ) {

  return (

    <>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-7 col-xl-6 col-xxl-5  my-5">
            <h4 className="mb-4">Оберіть урок</h4>
            <div className="words-items">

              {lessons.map((item, index) =>

                <Link className="word-item" 
                  key = { index }
                  to = '/lesson'
                  state = { item }
                  onClick = { () => (setCurrentLesson(item)) }>

                  <span className="word-item__word">{item.name}</span>

                </Link>

              )}
          
            </div>
          </div>
        </div>
      </div>

    </>

  );
}

